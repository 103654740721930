import { Vue, Component, Prop } from 'vue-property-decorator';
import { authService } from '@/modules/account/services/authService';
import { iscrittoService } from '@/modules/giornalisti/services/iscrittoService';
import { giornalistiCorsoService } from '../../services/giornalistiCorsoService';

import { loginStore } from '@/store/modules/login.store';
import { moduliService } from '@/modules/moduli/service/moduliService';
import { relatoriService } from '@/modules/corsiRelatori/services/relatoriService';
import { sessioneService } from '@/modules/sessioni/services/sessioneService';
import { stampaAttestatoPresenza } from '../../utils/exportPresenza';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { enteService } from '@/modules/enti/service/enteService';
import settings from '@/settings';
import { formatTime } from '@/filters';
import { crogService } from '@/modules/enti/service/crogService';
import { store } from '@/store';

@Component({
  name: 'GiornalistiCorsoDetail'
})
export default class GiornalistiCorsoDetail extends Vue {
  @Prop({ required: true })
  corsoId: string;

  corso: giornalista.corso.item = null;
  user: user.item = null;
  moduli: modulo.item[] = [];
  relatori: relatore.item[] = [];
  sessioni: sessione.item[] = [];

  iscrizione: iscritto.item = null;
  iscrittiAlCorso: number = 0;

  giornalista: giornalisti.item = null;
  ente: ente.item = null;

  query: iscritto.queryParams = {
    page: null,
    take: null
  };

  get enteOrganizzatore() {
    if (!this.corso) return null;
    return this.corso.referente?.enteDescrizione;
  }

  get referenteOrganizzatore(): string {
    if (!this.corso) return null;
    return this.corso.referente?.nomeCompleto;
  }

  get periodiFormativi(): periodoFormativo.item[] {
    return store.getters.periodiFormativi.list();
  };

  get postiDisponibili() {
    if (!this.corso) return 0;
    return (this.corso.numeroMassimoPartecipanti - this.iscrittiAlCorso) >= 0 ? this.corso.numeroMassimoPartecipanti - this.iscrittiAlCorso : 0;
  }

  get isGiornalistaInBlacklist() {
    return this.giornalista?.dataIngressoBlacklist && this.giornalista.contatoreBlacklist >= settings.appSetting.blacklist.assenzePerIngresso;
  }

   get cancellazioneIscrittoPossibile(): boolean {
    return new Date(new Date().setHours(0, 0, 0, 0)) <= (this.corso.continuativo ? 
      new Date(this.corso.dataFineIscrizione.setHours(0, 0, 0, 0)) :
      new Date(this.corso.data.setHours(0, 0, 0, 0)));
  }

  loading: boolean = false;
  crogs: ente.crog[] = [];
  mapPeriodiFormativi: Map<string, periodoFormativo.item> = new Map<string,periodoFormativo.item>();

  async created() {
    this.loading = true;
    this.crogs = await crogService.List();
    this.corso = await giornalistiCorsoService.DetailCorso(this.corsoId);
    for (const periodoFormativo of this.periodiFormativi) {
      if(!this.mapPeriodiFormativi.has(periodoFormativo.id)){
        // result.set(periodoFormativo.id, periodoFormativo.nome);
        this.mapPeriodiFormativi.set(periodoFormativo.id, periodoFormativo);
      }
    } 
    if (this.corso) {
      this.moduli = await moduliService.List(this.corsoId);
      this.relatori = await relatoriService.List(this.corsoId);
      this.sessioni = await sessioneService.List(this.corsoId);
      this.iscrittiAlCorso = await iscrittoService.Count(this.corsoId, { ...this.query, cancellato: false, ammesso: true });
    }
    
    await this.fetchData();
    this.loading = false;
  }

  get dataNonAfferentiOrdine(): Date {
    
    const intervalloDiPrelazioneInOre = settings.appSetting.corsi.prelazioneOrdineRegionale.intervalloDiPrelazioneInOre;
    const dataInizioIscrizione = new Date(this.corso.dataInizioIscrizione.setHours(0,0,0,0));
    return new Date(dataInizioIscrizione.setHours(dataInizioIscrizione.getHours() + intervalloDiPrelazioneInOre));
  }
  
  get dirittoDiPrelazione() {
    if(!this.crogs.map(m => m.id).includes(this.corso.enteId)) return false;
    return this.corso.enteId !== this.giornalista.anagraficaEnteId && this.dataNonAfferentiOrdine > new Date();
  }

  get dataInizioSessioni() {
    if (this.sessioni && this.sessioni.length > 0) {
      return this.sessioni[0].inizio;
    }
    return null;
  }

  get dataFineSessioni() {
    if (this.sessioni && this.sessioni.length > 0) {
      var ultimaSessione = this.sessioni[this.sessioni.length - 1];
      return new Date(new Date(ultimaSessione.inizio).setMinutes(ultimaSessione.inizio.getMinutes() + ultimaSessione.durata))
    }
    return null;
  }

  async fetchData() {
    this.loading = true;
    this.user = loginStore.state.userInfo || (await authService.WhoAmI());
    if (this.user && this.user.anagraficaGiornalistaId) {
      this.iscrizione = await iscrittoService.Detail(this.corsoId, this.user.anagraficaGiornalistaId);
      this.giornalista = await anagraficaService.Detail(this.user.anagraficaGiornalistaId);
      if (this.giornalista) {
        this.ente = await enteService.Detail(this.giornalista.anagraficaEnteId)
      }
    }
    this.loading = false;
  }

  get durata(): number {
    if (this.sessioni.length <= 0) return null;
    return this.sessioni.map(m => m.durata).reduce((a, c) => a += c);
  }

  async iscriviti() {
    if (this.user.anagraficaGiornalistaId) {
      this.iscrittiAlCorso = await iscrittoService.Count(this.corsoId, { ...this.query, cancellato: false, ammesso: true });
      if (this.postiDisponibili <= 0){
        this.$confirm(`I posti disponibili per questo corso sono terminati.<br />Vuoi iscriverti alla lista di attesa di questo corso?`, 'Attenzione!', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annulla',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        })
        .then(() => {
          iscrittoService
          .Iscriviti(this.corsoId, this.user.anagraficaGiornalistaId)
          .then(result => {
            this.iscrizione = result;
            this.$message({
              message: 'Iscrizione alla lista di attesa del corso avvenuta con successo',
              type: 'success'
            });
          })
          .catch(() => {
            this.$message({
              message: "Errore durante l'iscrizione alla lista di attesa del corso",
              type: 'error'
            });
          });
        })
      } else {
        iscrittoService
        .Iscriviti(this.corsoId, this.user.anagraficaGiornalistaId)
        .then(result => {
          this.iscrizione = result;
          this.$message({
            message: 'Iscrizione al corso avvenuta con successo',
            type: 'success'
          });
        })
        .catch(() => {
          this.$message({
            message: "Errore durante l'iscrizione al corso",
            type: 'error'
          });
        });
      }
    }
  }

  async annullaIscrizione() {
    if (this.cancellazioneIscrittoPossibile) {

      this.$prompt(
        `Attenzione, stai per rimuovere l'iscrizione al corso <strong>${this.corso.titolo}</strong><br/><span class="big">ATTENZIONE: Se confermi la cancellazione non sarà possibile iscriversi di nuovo a questo corso! </span>`,
        `Cancellazione iscrizione al corso`,
        {
          confirmButtonText: 'Ok',
          cancelButtonText: 'Annulla',
          dangerouslyUseHTMLString: true,
          inputType: 'textarea',
          inputPlaceholder: 'Inserisci la motivazione per la cancellazione',
          inputPattern: /^\s*\S.*$/,
          inputErrorMessage: `E' obbligatorio inserire la motivazione per cui l'iscrizione viene cancellata`
        }
      )
        .then(({ value }: any) => {
          iscrittoService
            .Remove(this.corsoId, this.user.anagraficaGiornalistaId, value)
            .then(() => this.fetchData())
            .catch(error => {
              this.$message({
                message: `Si è verificato un errore nella cancellazione dell'iscrizione al corso <strong>${this.corso.titolo}</strong>`,
                dangerouslyUseHTMLString: true,
                type: 'error',
                duration: 7200
              });
            });

          this.fetchData();
        })
        .catch(() => { });
    } else {
      this.$alert(
        `La cancellazione dell'iscrizione è <u><strong>possibile solo entro</strong></u><br />
        la data del corso (${formatTime(this.corso.data, 'DD/MM/YYYY')}).<br />`,
        `Non è possibile cancellare l'iscrizione`,
        {
          dangerouslyUseHTMLString: true
        }
      )
    }
  }

  async printCertificato() {
    stampaAttestatoPresenza(this.corso, this.giornalista, this.ente, this.iscrizione);
  }


  checkUriValido(url: string): boolean {
    const regEx = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    return (url && regEx.test(url))
  }
}
